

















































































































































































































































































































































import Component, { mixins } from 'vue-class-component';

import WidgetMixins from '../../../mixins/WidgetMixins';
import DialogMixins from '../../../mixins/DialogMixins';
import { Getter, namespace } from 'vuex-class';
import { ILogs, ILogsGraph, IPaginate, ISenderID, IUser } from '@/types/types';
import {
  dateFormat,
  truncateContent,
  loadWidget,
  loadView,
} from '@/utils/helpers';
import { Watch } from 'vue-property-decorator';
import moment from 'moment';
import { debounce } from 'lodash';

const logsModule = namespace('log');
const smsModule = namespace('sms');
const usersModule = namespace('users');

@Component({
  name: 'Index',
  components: {
    InternetConnection: loadWidget('pages/InternetConnection'),
    ToolBar: loadWidget('widgets/ToolBar'),
    SnackBar: loadWidget('widgets/SnackBar'),
    EmptyPage: loadWidget('pages/EmptyPage'),
    ProgressBar: loadWidget('widgets/CircularProgressLoader'),
    DoubleDatePicker: loadWidget('widgets/DoubleDatePicker'),
    LogDetailsBottomDrawerDialog: loadView(
      'pages/reports/dialogs/LogDetailsBottomDrawerDialog'
    ),
  },
  filters: {
    dateFormat,
    truncateContent,
  },
})
export default class Index extends mixins(WidgetMixins, DialogMixins) {
  @Getter('getCreditBalance') creditBalance!: number;
  @logsModule.Getter('getPagination') paginate!: IPaginate & { limit: number };
  @logsModule.Getter('getAllLogs') logs!: ILogs[];
  @logsModule.Getter('getLogsGraph') logsGraph!: Array<ILogsGraph>;
  @logsModule.Getter('getLogDetails') logDetails!: ILogs;
  @smsModule.Getter('getSenderIDList') approvedSenderIDlist!: Array<
    ISenderID & { id: number; slug: string; uuid: string }
  >;
  @usersModule.Getter('getCustomerDetails') customerDetails!: IUser;

  menu = false;
  renderChart = false;
  options: IPaginate = {
    itemsPerPage: 15,
    page: 1,
    total: 0,
  };
  breadcrumbs = [{ text: 'Sub Account', href: '/sub-accounts' }];
  headers: Array<{ text: string; value: string; align?: string }> = [
    {
      text: 'Delivered Date',
      value: 'completedDate',
    },
    {
      text: 'Sender',
      value: 'senderAddress',
    },
    {
      text: 'Recipient',
      value: 'receiverAddress',
    },
    {
      text: 'Status',
      value: 'deliveryStatus',
    },
    {
      text: 'Message',
      value: 'message',
    },
    {
      text: 'Credit Used',
      value: 'segments',
    },
  ];
  selectedId: number | null = null;
  statusColors: { [key: string]: { bg: string; text: string } } = {
    pending: {
      bg: 'rgba(253, 183, 132, 0.41)',
      text: 'rgb(202, 133, 0)',
    },
    ACCEPTED: {
      bg: 'rgba(253, 183, 132, 0.41)',
      text: 'rgb(202, 133, 0)',
    },
    failed: {
      bg: 'rgba(251, 107, 92, 0.41)',
      text: 'rgb(240, 43, 22)',
    },
    UNDELIV: {
      bg: 'rgba(251, 107, 92, 0.41)',
      text: 'rgb(240, 43, 22)',
    },
    UNDELIVERED: {
      bg: 'rgba(251, 107, 92, 0.41)',
      text: 'rgb(240, 43, 22)',
    },
    REJECTD: {
      bg: 'rgba(251, 107, 92, 0.41)',
      text: 'rgb(240, 43, 22)',
    },
    REJECTED: {
      bg: 'rgba(251, 107, 92, 0.41)',
      text: 'rgb(240, 43, 22)',
    },
    EXPIRED: {
      bg: 'rgb(102,10,10, 0.41)',
      text: 'rgb(106, 24, 16)',
    },
    success: { bg: 'rgba(65, 253, 77, 0.41)', text: 'rgb(7, 143, 60)' },
    DELIVRD: { bg: 'rgba(65, 253, 77, 0.41)', text: 'rgb(7, 143, 60)' },
    DELIVERED: { bg: 'rgba(65, 253, 77, 0.41)', text: 'rgb(7, 143, 60)' },
  };
  searchTerm = '';
  senderIdFilters: ISenderID[] = [];
  fileExtension = '';
  chartOptions: any = {
    chart: {
      zoom: { enabled: false },
      toolbar: { show: false },
      width: '100%',
      type: 'bar',
      height: '400px',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '10%',
        endingShape: 'rounded',
      },
    },
    colors: ['rgb(7, 143, 60)'],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 3,
      colors: ['transparent'],
    },
    xaxis: {
      axisBorder: {
        show: true,
      },
      labels: {
        show: true,
        styles: {
          cssClass: 'ft font-weight-medium',
        },
      },
      axisTicks: { show: true },
      categories: [],
    },
    grid: {
      show: false,
    },
    yaxis: {
      axisBorder: { show: false },
      labels: {
        show: true,
        styles: {
          cssClass: 'ft font-weight-medium',
        },
        formatter: (value: number) => {
          return value.toFixed(0);
        },
      },
      axisTicks: { show: false },
    },
    fill: {
      opacity: 1,
    },
  };

  series: Array<{ name: string; data: number[] }> = [];

  dateFilter = '';
  page = 1;
  dataLoaderPlaceholder = 'Loading data ...';

  handleOnSearchTextChange = debounce(this.searchText, 1000);

  searchText(text: string): void {
    this.$store.dispatch('log/list', {
      page: this.page,
      limit: this.options?.itemsPerPage,
      query: `${this.dateFilter}${text ? '&searchTerm=' + text : ''}${
        this.senderIdFilters.length > 0
          ? '&filters=' +
            this.senderIdFilters.map((item) => item.name).join(',')
          : ''
      }`,
      subAccountId: this.$route.params.id,
    });
  }

  @Watch('$route.params', { deep: true })
  onRouteParamsChange(obj: { id: number }): void {
    if (!obj?.id) {
      this.$store.dispatch('log/list', {
        page: this.page,
        limit: this.options?.itemsPerPage,
        query: `&date=${moment()
          .subtract(1, 'month')
          .format('YYYY-MM-DD')},${moment().format('YYYY-MM-DD')}`,
      });
    }
  }

  @Watch('senderIdFilters')
  onSenderIdFiltersChange(filters: ISenderID[]): void {
    this.$store.dispatch('log/list', {
      page: this.page,
      limit: this.options?.itemsPerPage,
      query: `${this.dateFilter}${
        this.searchTerm ? '&searchTerm=' + this.searchTerm : ''
      }${
        filters.length > 0
          ? '&filters=' + filters.map((item) => item.name).join(',')
          : ''
      }`,
      subAccountId: this.$route.params.id,
    });
  }

  @Watch('searchTerm')
  onSearchTermChange(term: string): void {
    this.handleOnSearchTextChange(term);
  }

  @Watch('page')
  onOptionsChange(page: number): void {
    this.$store.dispatch('log/list', {
      page: page ?? 1,
      limit: this.paginate.limit ?? 15,
      query: `${this.dateFilter}${
        this.searchTerm ? '&searchTerm=' + this.searchTerm : ''
      }${
        this.senderIdFilters.length > 0
          ? '&filters=' +
            this.senderIdFilters.map((item) => item.name).join(',')
          : ''
      }`,
      subAccountId: this.$route.params.id,
    });
  }

  @Watch('logsGraph')
  onLogsGraphChange(data: Array<ILogsGraph>): void {
    this.chartOptions = {
      ...this.chartOptions,
      xaxis: {
        ...this.chartOptions.xaxis,
        categories: data?.map(({ date }) => date),
      },
    };
    this.series = [
      {
        name: "Total SMS's Delivered",
        data: data?.map(({ total_count }) => total_count),
      },
    ];
  }

  filterLogsByDate(date: string): void {
    this.page = 1;
    this.dateFilter = `&date=${date}`;
    this.$store.dispatch('log/list', {
      page: this.page,
      limit: this.paginate.limit,
      query: `${this.dateFilter}${
        this.senderIdFilters.length > 0
          ? '&filters=' +
            this.senderIdFilters
              .map((item) => item.name?.toLowerCase()?.replace(/\s+/g, ''))
              .join(',')
          : ''
      }${this.searchTerm ? '&searchTerm=' + this.searchTerm : ''}`,
      subAccountId: this.$route.params.id,
    });
  }

  viewDetails(id: number): void {
    this.selectedId = id;
    //this.$store.dispatch('log/details', id);
    this.open('details', id, 'log/details');
  }

  exportFile(): void {
    const fileName = `SMS-DLVRY-${this.dateFilter.replaceAll(/[^\d]+/g, '')}.${
      this.fileExtension
    }`;
    this.$store.dispatch('log/scheduleLogsDownload', {
      subAccountId: this.$route.params.id,
      query: `${this.dateFilter.replace(/&/g, '?')}${
        this.senderIdFilters.length > 0
          ? '&filters=' +
            this.senderIdFilters.map((item) => item.name).join(',')
          : ''
      }${
        this.searchTerm ? '&searchTerm=' + this.searchTerm : ''
      }&file=${fileName}`,
      fileName,
    });
  }
  created(): void {
    this.$nextTick(() => {
      this.renderChart = true;
    });
    this.page = 1;
    this.dateFilter = `&date=${moment()
      .subtract(1, 'month')
      .format('YYYY-MM-DD')},${moment().format('YYYY-MM-DD')}`;
    this.$store.dispatch('log/list', {
      page: this.page,
      limit: 15,
      query: this.dateFilter,
      subAccountId: this.$route.params.id,
    });
    if (this.approvedSenderIDlist.length === 0) {
      this.$store.dispatch('sms/listSenderID');
    }
    if (this.$route.params.id) {
      this.$store.dispatch('users/customerDetails', {
        accountId: this.$route.params.id,
      });
    }
    // set data placeholder after 3 sec
    setInterval(() => {
      this.dataLoaderPlaceholder = '😔 Failed to load data';
    }, 2000);
  }
}
